const Resize = () => {
	function SetWidth(elem, value) {
		elem.width = value;
		elem.style.width = value + 'px';
	}

	function SetHeight(elem, value) {
		elem.height = value;
		elem.style.height = value + 'px';
	}
	var canvas = document.getElementById('modelcanvas');
	SetHeight(canvas, $(".box-l").height() - 40);
	SetWidth(canvas, $(".box-l").width());
	$('.dialog').css('left', (($(window).width() - $('.dialog').width()) / 2.0) + 'px');
	$('.dialog').css('top', (($(window).height() - $('.dialog').height()) / 3.0) + 'px');
	window.jsmResize ? window.jsmResize() : null;
};
class ImporterButtons {
	constructor(parent) {
		this.buttonsDiv = $('<div>').attr('id', 'buttons').appendTo(parent);
	};
	empty() {
		this.buttonsDiv = null;
	}
	AddLogo(title) {
		var logoDiv = $('<div>').attr('id', 'logo').html(title).appendTo(this.buttonsDiv);
		logoDiv.click(function () {
			location.hash = '';
			location.reload();
		});
	};

	AddYiZaoButton(title) {
		var logoDiv = $('<div>').attr('class', 'btn btn-primary screen').html(title).appendTo(this.buttonsDiv);
		logoDiv.click(function () {
			$('body').css('overflow', 'hidden');
			$('.make3d').addClass('on');
			$('.topbutton.close').show();
			$('.btn.screen').hide();
			Resize();
		});
	};


	AddButton(image, title, onClick, classify = '') {
		var buttonImage = $('<img>').addClass('topbutton ' + classify).attr('src', image).attr('title', title).appendTo(this.buttonsDiv);
		buttonImage.click(function () {
			onClick();
		});
	};

	AddToggleButton(image, toggleImage, title, onClick) {
		var buttonImage = $('<img>').addClass('topbutton').attr('src', image).attr('title', title).appendTo(this.buttonsDiv);
		var isOn = true;
		buttonImage.click(function () {
			isOn = !isOn;
			if (isOn) {
				buttonImage.attr('src', image);
			} else {
				buttonImage.attr('src', toggleImage);
			}
			onClick();
		});
	};
}
class ExtensionButtons {
	constructor(parent) {
		if($('#top #extbuttons').length==0){
			this.buttonsDiv = $('<div>').attr('id', 'extbuttons').appendTo(parent);
		}else{
			$('#top').append($('#extbuttons'));
		}
	};
	empty() {
		this.buttonsDiv = null;
	}
	GetButtonsDiv() {
		return this.buttonsDiv;
	};
}

function addButton(viewer, SetNamedView) {
	if(!SetNamedView)SetNamedView=()=>{};
	$('#top #buttons').remove();
	var top = $('#top');
	var importerButtons = new ImporterButtons(top);

	importerButtons.AddButton(require('../images/openfile.png'), '打开文件', function () {
		var fileInput = document.getElementById('file');
		fileInput.click();
	});
	importerButtons.AddToggleButton(require('../images/fixup.png'), require('../images/fixupgray.png'), '启用/禁用固定向量', function () {
		if(viewer)viewer.navigation.EnableFixUp(!viewer.navigation.cameraFixUp);
	});
	importerButtons.AddButton(require('../images/top.png'), '设定向量 (Z)', function () {
		SetNamedView('z');
	});
	importerButtons.AddButton(require('../images/bottom.png'), '设定向量 (-Z)', function () {
		SetNamedView('-z');
	});
	importerButtons.AddButton(require('../images/front.png'), '设定向量 (Y)', function () {
		SetNamedView('y');
	});
	importerButtons.AddButton(require('../images/back.png'), '设定向量 (-Y)', function () {
		SetNamedView('-y');
	});
	importerButtons.AddButton(require('../images/left.png'), '设定向量 (X)', function () {
		SetNamedView('x');
	});
	importerButtons.AddButton(require('../images/right.png'), '设定向量 (-X)', function () {
		SetNamedView('-x');
	});
	importerButtons.AddButton(require('../images/fitinwindow.png'), '适合窗口', function () {
		$('.btn.screen').show();
		$('.topbutton.close').hide();
		$('.make3d').removeClass('on');
		$('body').css('overflow', 'auto');
		Resize();
	}, 'close');
	importerButtons.AddYiZaoButton('全屏');
	importerButtons.empty();
	return new ExtensionButtons(top);
}
export {
	addButton
}
<template>
  <div class="service-add">
    <h3>免费发布需求 快速匹配供应商</h3>
    <span class="tip">仅需6步，即可完成产品生产</span>
    <div class="add-flow">
      <div class="item" v-for="(item, index) in addFlowList" :key="item.id">
        <div>
          <i :class="['iconfont', item.icon]"></i>
          <p>{{ item.title }}</p>
        </div>
        <span v-if="index < addFlowList.length - 1">-----></span>
      </div>
    </div>
    <div class="add-main">
      <div class="form">
        <el-form
          :model="upForm"
          ref="upForm"
          label-width="120px"
          size="small"
          class="up-form"
          label-suffix="："
        >
          <el-form-item
            prop="title"
            label="需求名称"
            :rules="[
              { required: true, message: '请输入需求名称', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="upForm.title"
              placeholder="请输入需求名称"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="mobile"
            label="手机号"
            :rules="[
              {
                required: true,
                validator: validatorPhone,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="upForm.mobile"
              placeholder="请输入手机号"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="validCode"
            label="图片验证码"
            v-show="!hadLogin"
            :rules="[
              {
                required: !hadLogin,
                message: '请输入图片验证码',
                trigger: 'blur',
              },
            ]"
          >
            <el-row>
              <el-col :span="18">
                <el-input
                  v-model="upForm.validCode"
                  placeholder="请输入图片验证码"
                ></el-input>
              </el-col>
              <el-col :span="5" :offset="1" style="text-align: right">
                <img :src="this.validCode" @click="getValidCode" alt="" />
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            prop="regValidCode"
            label="验证码"
            v-show="!hadLogin"
            :rules="[
              { required: !hadLogin, message: '请输入验证码', trigger: 'blur' },
            ]"
          >
            <el-row>
              <el-col :span="18">
                <el-input
                  v-model="upForm.regValidCode"
                  placeholder="请输入验证码"
                ></el-input>
              </el-col>
              <el-col :span="5" :offset="1" style="text-align: right">
                <el-button
                  type="primary"
                  :loading="codeLoad"
                  :disabled="
                    codeText != '发送验证码' && codeText != '重新发送验证码'
                  "
                  @click="getCode"
                  >{{ codeText }}</el-button
                >
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item
            prop="categoryCode"
            label="服务分类"
            :rules="[
              { required: true, message: '请选择服务分类', trigger: 'change' },
            ]"
          >
            <el-cascader
              v-model="upForm.categoryCode"
              :options="typeList"
              placeholder="请选择服务分类"
            ></el-cascader>
          </el-form-item>
          <el-form-item
            prop="userName"
            label="姓名"
            :rules="[
              { required: true, message: '请输入姓名', trigger: 'blur' },
            ]"
          >
            <el-input
              v-model="upForm.userName"
              placeholder="请输入姓名"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="email"
            label="邮箱"
            :rules="[
              {
                required: true,
                validator: validatorEmail,
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              v-model="upForm.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item
            prop="description"
            label="描述"
            :rules="[
              { required: true, message: '请输入描述', trigger: 'blur' },
            ]"
          >
            <el-input
              type="textarea"
              v-model="upForm.description"
              placeholder="请输入描述"
            ></el-input>
          </el-form-item>
          <el-form-item prop="files" label="需求上传">
            <!-- <el-upload class="upload-demo" drag :action="action">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
              <div class="el-upload__tip" slot="tip">
                支持stp/stl/igs/obj格式文件
              </div>
            </el-upload> -->
            <upload
              ref="upload"
              :bizKey="bizKey"
              :fileName.sync="filesName"
              @success="upSuccess"
            />
          </el-form-item>
          <el-form-item class="box">
            <el-checkbox v-model="checked">我已阅读并同意</el-checkbox>
            <span class="box-deal" @click="showDeal = true"
              >《用户使用协议》</span
            >
          </el-form-item>
        </el-form>
      </div>
      <div class="desc">
        <h3>坐享更<span>高效专业</span>的服务</h3>
        <div class="list">
          <div class="item">
            <p><img src="@/assets/images/public/icon_hj.jpg" alt="" /></p>
            <div>
              <p>需求快速响应</p>
              <span>顾问全程服务</span>
            </div>
          </div>
          <div class="item">
            <p><img src="@/assets/images/public/icon_bz.jpg" alt="" /></p>
            <div>
              <p>标准化服务</p>
              <span>直到满意为止</span>
            </div>
          </div>
          <div class="item">
            <p><img src="@/assets/images/public/icon_b.jpg" alt="" /></p>
            <div>
              <p>官方担保交易</p>
              <span>验收合格在付款</span>
            </div>
          </div>
          <div class="item">
            <p><img src="@/assets/images/public/icon_s.jpg" alt="" /></p>
            <div>
              <p>交易出问题</p>
              <span>保证金赔付</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button type="primary" @click="submit" :loading="btnLoading"
        >免费发布需求</el-button
      >
      <el-button type="primary" @click="visibles = false"
        >在线客服报价</el-button
      >
    </div>
    <el-dialog
      center
      :modal-append-to-body="true"
      :append-to-body="true"
      :visible.sync="showDeal"
      title="用户协议"
      width="1000px"
    >
      <div class="deal-main">
        <p>
          <strong>重要提示：</strong
          ><br />请您仔细阅读以下条款，并确认您已完全理解本协议之规定，尤其是免除及限制责任的条款、知识产权条款、法律适用及争议解决条款。
        </p>
        <p>
          若您对本声明或本协议任何条款有异议，请停止注册或使用简书网（jianshu.com，包括手机、电脑等移动设备客户端，下同）所提供的全部服务。
        </p>
        <p>
          <strong>一、协议的接受、变更与补充</strong
          ><br />1、勾选本协议前选项框并点击“注册”，将视为您签署了本协议，表明您自愿接受本协议全部条款的约束，本协议将构成您与上海佰集科技有限公司及其经营的“简书”平台（以下统称“简书”，包括相关关联方）之间具有约束力的法律文件。无论您是进入简书浏览网页，还是在简书上发布任何内容，或者是直接或通过各类方式（如站外API引用等）间接使用简书网服务和数据的行为，都将被视作已无条件接受本声明所涉全部内容。
        </p>
        <p>
          2、简书有权利对本协议进行修改，协议修改后，简书将通过在相关页面公告或发送通知等方式公布修改的内容，修改后的协议一经公布即有效的代替原协议。如果您不同意本协议的修改，请立即停止访问或使用本网站或取消已经获得的服务；如果您选择继续访问或使用本网站，则视为您已接受本协议的修改。
        </p>
        <p>
          3、签署的本协议所列明的条款，并不能完全涵盖您与简书之间所有的权利和义务。因此，简书不定期公布的其他声明、规则等均视为本协议之补充协议，为本协议不可分割的组成部分，与本协议具有同等法律效力。
        </p>
        <p>4、如本协议与简书平台其它协议条款不一致，以其它协议条款内容为准。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="agree">我已阅读并同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import upload from "./upload";
export default {
  components: {
    upload,
  },
  computed: {
    hadLogin() {
      return this.$store.state.userInfo.userCode;
    },
    upForm: {
      get() {
        {
          let { mobile, userName, email } = this.$store.state.userInfo;
          this.forms = {
            ...this.forms,
            mobile,
            userName,
            email,
          };
          return this.forms;
        }
      },
      set(val) {
        this.forms = val;
      },
    },
  },
  data() {
    return {
      forms: {
        mobile: "",
        regValidCode: "",
        categoryCode: [],
        userName: "",
        email: "",
        description: "",
        files: "",
        title: "",
        validCode: "",
      },
      btnLoading: false,
      validCode: "",
      codeText: "发送验证码",
      codeLoad: false,
      showDeal: false,
      checked: false,
      files: [],
      filesName: "",
      action: "",
      formData: {},
      typeList: [],
      addFlowList: [
        { id: 1, title: "需求提交", icon: "icon-tijiao" },
        { id: 2, title: "专家分析", icon: "icon-fenxi" },
        { id: 3, title: "服务商匹配", icon: "icon-fuwushang" },
        { id: 4, title: "报价回执", icon: "icon-yueduhuizhi" },
        { id: 5, title: "注册下单", icon: "icon-xiadanyuyue" },
        { id: 6, title: "协同制造", icon: "icon-zhizao" },
      ],
      bizKey: "",
      bizType: "yzTaskMain_file",
      fileUploadId: "",
      idWorker: "",
      tipText: "",
    };
  },
  mounted() {
    this.getValidCode();
    this.getClassifyData();
  },
  methods: {
    validatorEmail(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入邮箱"));
      } else if (
        value != "" &&
        !/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(
          value
        )
      ) {
        callback(new Error("邮箱格式错误"));
      } else {
        callback();
      }
    },
    getValidCode() {
      this.validCode = "";
      this.$nextTick(() => {
        this.validCode = `${
          window.config.api
        }/validCode?${new Date().getTime()}`;
      });
    },
    getCode() {
      let i = 0;
      this.$refs.upForm.validateField(["mobile", "validCode"], (err) => {
        if (!err) {
          i++;
          if (i == 2) {
            this.codeLoad = true;
            this.$api
              .post(
                `/api/v1/getPostTaskMainValidCode?username=${this.upForm.mobile}&validCode=${this.upForm.validCode}`
              )
              .then((res) => {
                this.codeLoad = false;
                this.codeText = "60s后重新获取";
                this.noteDisabled = true;
                let _i = 59;
                let _set = setInterval(() => {
                  if (_i > 0) {
                    this.codeText = `${_i}s后重新获取`;
                    _i--;
                  } else {
                    this.codeText = "发送验证码";
                    this.noteDisabled = false;
                    clearInterval(_set);
                  }
                }, 1000);
              })
              .catch((err) => {
                this.codeLoad = false;
                this.getValidCode();
              });
          }
        }
      });
    },
    validatorPhone(rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (value != "" && !/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    relevance(files) {
      this.files = files;
      this.goRelevance(files);
    },
    goRelevance(files, i = 0) {
      let params = {
        bizKey: this.bizKey,
        bizType: this.bizType,
        fileUploadId: files[i].fileUploadId,
      };
      if (window.config.api == "/papi") {
        params.__sid = window.config.sid;
      }
      this.$api
        .post("/api/v1/unFileAndForm", this.$common.getFormData(params))
        .then((res) => {
          if (res.code == 1) {
            if (files.length - 1 == i) {
              this.btnLoading = false;
              this.$message({
                message: this.tipText,
                type: "success",
                duration: 1500,
                onClose: () => {
                  location.href = "/service/list";
                },
              });
            } else {
              i++;
              this.goRelevance(files, i);
            }
          } else {
          }
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    upService(fn) {
      let {
        mobile,
        regValidCode,
        categoryCode,
        userName,
        email,
        description,
        title,
        validCode,
      } = this.upForm;
      categoryCode = categoryCode[categoryCode.length - 1];
      this.$api
        .post(
          `/api/v1/postTaskMain`,
          this.$common.getFormData({
            mobile,
            validCode,
            regValidCode,
            categoryCode,
            userName,
            email,
            description,
            title,
            isAgree: this.checked ? "1" : "",
          }),
          { Headers: { "Content-Type": "application/x-www-form-urlencoded" } }
        )
        .then((res) => {
          if (fn && res.code == 1) {
            this.bizKey = res.data.id;
            this.tipText = res.message;
            this.$nextTick(() => {
              fn();
            });
          } else {
            this.btnLoading = false;
            if (res.code == 1) {
              this.$message({
                message: res.message,
                type: "success",
                duration: 1500,
                onClose: () => {
                  location.href = "/service/list";
                },
              });
            } else {
              this.$message({
                message: res.message,
                type: "error",
                duration: 1500,
                onClose: () => {},
              });
            }
          }
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
    submit() {
      this.$refs.upForm.validate((valid) => {
        if (valid) {
          if (!this.checked) {
            this.$message({
              message: "请阅读并同意《用户使用协议》",
              type: "error",
            });
            return;
          }
          this.btnLoading = true;
          if (this.filesName != "") {
            this.upService(() => {
              this.$refs.upload.submit();
            });
          } else {
            this.upService();
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    upSuccess(files) {
      this.relevance(files);
    },
    agree() {
      this.showDeal = false;
      this.checked = true;
    },
    inits() {
      this.checked = false;
      this.upForm = {
        phone: "",
        regValidCode: "",
        categoryCode: "",
        userName: "",
        email: "",
        description: "",
        files: "",
        title: "",
      };
    },
    getClassifyData() {
      this.$sapi.get("/api/v1/getYzTaskCategoryList.json").then((res) => {
        let _json = {};
        let pList = [];
        let list = res.filter((item) => {
          if (item.parentCode == "0") {
            _json[item.id] = [];
            pList.push(item);
          }
          return item.parentCode != "0";
        });
        list.forEach((item) => {
          if (_json[item.parentCode]) _json[item.parentCode].push(item);
        });
        this.typeList = pList.map((item) => {
          if (_json[item.id] && _json[item.id].length) {
            item.children = _json[item.id].map((item) => {
              return {
                value: item.id,
                label: item.name,
              };
            });
            item.children = item.children.map((items) => {
              if (_json[items.id] && _json[items.id].length) {
                items.children = _json[items.id].map((item) => {
                  return {
                    value: item.id,
                    label: item.name,
                  };
                });
              }
              return items;
            });
          }
          return {
            value: item.id,
            label: item.name,
            children: item.children,
          };
        });
      });
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/up.scss";
</style>
<template>
  <el-dialog
    class="up-dialog"
    :visible.sync="visibles"
    width="1000px"
    :modal-append-to-body="true"
    :append-to-body="true"
    :lock-scroll="true"
    :before-close="handleClose"
  >
    <up ref="up" />
  </el-dialog>
</template>
<script>
import up from "./up";
export default {
  components: { up },
  props: {
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  computed: {
    visibles: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    handleClose() {
      this.visibles = false;
      this.$refs.up.inits();
    },
  },
};
</script>
<style lang="scss">
.up-dialog {
  .el-dialog {
    margin: 4vw auto 2vw !important;
  }
  .el-dialog__body {
    padding-top: 0;
  }
}
</style>
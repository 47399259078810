<template>
  <div :class="['upload', mode]">
    <label class="up-load" id="drop_area" v-if="mode == 'file' && !hide">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <span v-if="!loading" class="info">点击或拖拽文件上传</span>
      <span v-if="loading" class="success">文件加载中...</span>
    </label>
    <label class="up-load btn" v-if="mode == 'btn'">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <el-button type="primary">{{
        files.length ? "重新上传" : "上传文件"
      }}</el-button>
    </label>
    <label class="up-load btn" v-if="mode == 'adjunct'">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <span v-if="!preview">添加附件</span>
    </label>
    <label class="up-load image" v-if="mode == 'image'">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <div class="image-list" v-for="item in files" :key="item.id">
        <span v-if="!item.imageUrl">加载中...</span>
        <p v-if="!multiple">重新上传</p>
        <el-image
          :src="item.imageUrl"
          v-if="item.imageUrl"
          fit="cover"
        ></el-image>
      </div>
      <div v-if="!files.length">
        <div class="image-list" v-for="item in filelist" :key="item.id">
          <span v-if="!item.fileUrl">加载中...</span>
          <p v-if="!multiple">重新上传</p>
          <el-image
            :src="item.fileUrl"
            v-if="item.fileUrl"
            fit="cover"
          ></el-image>
        </div>
      </div>
      <el-button
        type="primary"
        size="small"
        v-if="(!filelist.length || multiple) && !preview"
        >上传文件</el-button
      >
    </label>
    <label class="up-load image" v-if="mode == 'sign'">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <div class="image-list sign" v-for="item in files" :key="item.id">
        <span v-if="!item.imageUrl">加载中...</span>
        <p v-if="!multiple">重新上传</p>
        <el-image
          :src="item.imageUrl"
          v-if="item.imageUrl"
          fit="cover"
        ></el-image>
      </div>
      <el-button type="primary" v-if="(!files.length || multiple) && !preview"
        >上传签章</el-button
      >
    </label>
    <label class="up-load btn" v-if="mode == 'craft'">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <el-button type="primary" size="small" icon="el-icon-upload">
        {{ btnText }}
      </el-button>
    </label>
    <div
      :class="['contract-file-list', preview ? 'on' : '']"
      v-if="mode == 'contract'"
    >
      <template v-for="item in filelist">
        <div class="contract-file-item" v-if="item.status != 1" :key="item.id">
          <div class="name">
            <span class="el-icon-document"></span>
            {{ item.fileName }}
          </div>
          <div class="size" v-if="preview">
            {{ getSize(item.fileEntity.fileSize) }}
          </div>
          <div class="date" v-if="preview">{{ item.updateDate }}</div>
          <div class="down" v-if="preview">
            <span @click="goDown(item)">下载</span>
          </div>
          <div class="down" v-if="preview">
            <span @click="goView(item)">预览合同</span>
          </div>
          <div class="down" v-if="preview">
            已下载<span>{{ (item.extend && item.extend.extendI4) || 0 }}</span
            >次
          </div>
          <div class="del" v-if="!preview" @click="delFile(item)">删除</div>
        </div>
      </template>
      <div class="contract-file-item" v-for="item in files" :key="item.id">
        <div class="name">
          <span class="el-icon-document"></span>
          {{ item.name }}
        </div>
        <div class="size" v-if="preview">{{ getSize(item.size) }}</div>
        <div class="date" v-if="preview">刚刚</div>
        <div class="down" v-if="preview">-</div>
        <div class="down" v-if="preview">-</div>
        <div class="down" v-if="preview">-</div>
        <div class="del" v-if="!preview" @click="delFile(item)">删除</div>
      </div>
    </div>
    <label class="up-load contract" v-if="mode == 'contract'">
      <input
        type="file"
        name="upfile"
        v-if="inputHide && !preview"
        :id="idName"
        :multiple="multiple"
        @change="change"
      />
      <div class="tip-text" v-if="!filelist.length && !files.length">
        暂未上传任何文件
      </div>
      <el-button type="primary" v-if="!preview">{{
        files.length ? "继续上传" : "上传文件"
      }}</el-button>
    </label>
    <div
      :class="['pre-list', mode == 'btn' ? 'on' : '']"
      v-if="
        (files.length || filelist.length) &&
        mode != 'image' &&
        mode != 'sign' &&
        mode != 'contract' &&
        mode != 'craft'
      "
    >
      <span v-if="mode != 'btn'">文件：</span>
      <div
        :class="['item', 'on', item.status == 1 ? 'none' : '']"
        v-for="item in filelist"
        :key="item.id"
      >
        <span class="el-icon-circle-check"></span>
        {{ item.fileName }}
        <span
          v-if="!preview"
          class="el-icon-delete"
          @click="delFile(item)"
        ></span>
      </div>
      <div
        :class="[
          'item',
          item.status == 'success' ? 'on' : '',
          item.status == 'exception' ? 'err' : '',
        ]"
        v-for="item in files"
        :key="item.name"
      >
        <span
          class="el-icon-circle-check"
          v-if="item.status == 'success'"
        ></span>
        <span
          class="el-icon-circle-close"
          v-if="item.status == 'exception'"
        ></span>
        {{ item.name }}
        <span
          class="el-icon-delete"
          v-if="!preview"
          @click="delFile(item)"
        ></span>
      </div>
    </div>
    <div :class="['up-list', upStart ? 'on' : '']" v-if="files.length">
      <div
        :class="[
          'item',
          item.hide == 1 ? 'on' : '',
          item.hide == 2 || item.discard ? 'hide' : '',
        ]"
        v-for="item in files"
        :key="item.name"
      >
        <div class="title">
          <span>{{ item.name }}</span>
          <div class="operate">
            <span
              class="el-icon-video-play"
              v-if="item.stop"
              @click="start(item)"
              title="开始上传"
            ></span>
            <!-- <span
              class="el-icon-video-pause"
              v-if="item.start"
              title="暂停上传"
              @click="stop(item)"
            ></span> -->
            <span
              class="el-icon-refresh-right"
              v-if="item.error"
              @click="reset(item)"
              title="重新上传"
            ></span>
            <span
              class="el-icon-delete"
              @click="deletes(item)"
              v-if="item.error"
              title="删除"
            ></span>
          </div>
        </div>
        <div class="tip" v-if="item.tip">
          {{ item.tip }}
        </div>
        <el-progress
          :percentage="item.percentage"
          :stroke-width="8"
          v-if="item.status"
          :status="item.status"
        ></el-progress>
        <el-progress
          :percentage="item.percentage"
          :stroke-width="8"
          v-if="!item.status"
        ></el-progress>
      </div>
    </div>
    <el-dialog
      :title="title"
      width="95vw"
      class="iframe-dialog"
      :visible.sync="dialogVisible"
      :modal-append-to-body="true"
      :append-to-body="true"
      :lock-scroll="true"
    >
      <iframe :src="fileUrl" frameborder="0"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import md5 from "js-md5";
export default {
  props: {
    btnText: {
      type: String,
      default: "上传工艺文件",
    },
    bizKey: {
      type: String | Number,
      default: "",
    },
    bizType: {
      type: String | Number,
      default: "yzTaskMain_file",
    },
    fileName: {
      type: String,
      default: "",
    },
    hide: {
      type: Boolean,
      default: false,
    },
    fileId: {
      type: String,
      default: "",
    },
    uploadType: {
      type: String,
      default: "file",
    },
    preview: {
      type: Boolean | Number,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    format: {
      type: String,
      default: "stp/step/stl/igs/obj/doc/docx/zip/rar/xls/xlsx",
    },
    mode: {
      type: String,
      default: "file",
    },
    keyName: {
      type: String | Number,
      default: "",
    },
    filelist: {
      type: Array,
      default() {
        return [];
      },
    },
    fileData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      title: "",
      idName: "",
      upStart: false,
      hasUp: false,
      fileUploadId: "",
      fileEntityId: "",
      files: [],
      complete: "",
      loading: false,
      inputHide: true,
      fileUrl: "",
    };
  },
  watch: {
    files(newval, oldval) {
      this.$emit("update:fileData", newval);
    },
  },
  created() {
    this.idName = `upfile${new Date().getTime()}`;
  },
  methods: {
    getSize(size) {
      if (size < 1024) {
        return `${size}b`;
      } else if (size < 1024 * 1024) {
        return `${(size / 1024).toFixed(2)}kb`;
      } else if (size < 1024 * 1024 * 1024) {
        return `${(size / 1024 / 1024).toFixed(2)}m`;
      } else if (size < 1024 * 1024 * 1024 * 1024) {
        return `${(size / 1024 / 1024 / 1024).toFixed(2)}g`;
      }
    },
    start(items) {
      this.files.forEach((item, index) => {
        if (item.id == items.id) {
          this.goUp(item, index);
        }
      });
    },
    stop(items) {},
    reset(items) {
      this.files.forEach((item, index) => {
        if (item.id == items.id) {
          this.goUp(item, index);
        }
      });
    },
    delFile(item) {
      this.$confirm(`您确定要删除${item.name || item.fileName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.inputHide = false;
        if (item.fileName) {
          this.$emit("removeFile", item);
        } else {
          this.files = this.files.filter((items) => {
            return items.id != item.id;
          });
        }
        this.$nextTick(() => {
          this.inputHide = true;
        });
      });
    },
    deletes(item) {
      this.$confirm(`您确定要删除${item.name || item.fileName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.files = this.files.filter((items) => {
          return items.id != item.id;
        });
        if (!this.files.length) {
          this.upStart = false;
        }
      });
    },
    checkFile(item, i = 0) {
      if (i == 0) this.loading = true;
      let formData = new FormData();
      formData.append("__ajax", "json");
      window.config.api == "/papi"
        ? formData.append("__sid", window.config.sid)
        : null;
      formData.append("fileMd5", md5(item.name));
      formData.append("fileName", item.name);
      this.$api
        .post("/a/file/upload.json", formData, { mum: true })
        .then((res) => {
          if (this.mode == "image" || this.mode == "sign") {
            var reader = new FileReader();
            reader.onload = () => {
              this.files = this.files.map((items) => {
                if (items.id == item.id) {
                  items.imageUrl = reader.result;
                }
                return items;
              });
            };
            reader.readAsDataURL(item.file);
          }
          this.files = this.files.map((items) => {
            if (items.id == item.id) {
              items.res = res;
              items.fileUploadId = res.fileUpload.id;
              items.fileEntityId =
                res.fileEntityId || res.fileUpload.fileEntity.id;
              if (res.result == "true") {
                items.hasUp = true;
              } else {
                items.hasUp = false;
              }
            }
            return items;
          });
          if (i == this.files.length - 1) {
            this.loading = false;
            this.$emit("checkSuccess", this.files);
          }
        })
        .catch((err) => {
          if (i == this.files.length - 1) {
            this.loading = false;
            this.$emit("checkSuccess", this.files);
          }
        });
    },
    clear() {
      this.inputHide = false;
      this.files = [];
      this.$emit("update:fileName", "");
      this.$nextTick(() => {
        this.inputHide = true;
      });
    },
    change() {
      let file = [];
      let _this = this;
      if (!this.hide) {
        file = document.getElementById(this.idName).files;
      } else if (this.fileId) {
        file = document.getElementById(this.fileId).files;
      }
      let arr = [];
      let isType = false;
      for (let i = 0; i < file.length; i++) {
        let is = _this.files.find((item) => item.name == file[i].name);
        var nameArray = file[i].name.split(".");
        var type = nameArray[nameArray.length - 1];
        let formatArray = _this.format.split("/");
        let hasType = formatArray.find((item) => item.toLowerCase() == type);
        if (!hasType) {
          isType = true;
        }
        if (!is && hasType) {
          arr.push({
            file: file[i],
            id: file[i].name + new Date().getTime(),
            success: false,
            error: false,
            stop: false,
            start: false,
            delete: false,
            name: file[i].name,
            size: file[i].size,
            fileUploadId: "",
            fileEntityId: "",
            status: "",
            tip: "",
            discard: false,
            type,
            imageUrl: "",
            keyName: _this.keyName,
          });
        }
      }
      if (isType) {
        this.$message({
          message: `请上传${this.format}格式文件！`,
          type: "error",
          duration: 1500,
        });
        if (!this.multiple) return;
      }
      if (!this.multiple && !arr.length) return;
      this.files = this.multiple ? [...this.files, ...arr] : arr;
      this.$emit(
        "update:fileName",
        this.files.map((item) => item.name).join(",")
      );
      arr.forEach((item, index) => {
        this.checkFile(item, index);
      });
    },
    hideBox() {
      let is = true;
      this.files.forEach((item) => {
        if (item.success == false) {
          is = false;
        }
      });
      if (is) {
        this.$emit("success", this.files);
        setTimeout(() => {
          this.upStart = false;
        }, 500);
      }
    },
    goUp(item, i = 0) {
      if (i == 0) this.upStart = true;
      this.files = this.files.map((items) => {
        if (items.id == item.id) {
          items.start = true;
          items.percentage = 0;
          items.success = false;
          items.error = false;
          items.stop = false;
          items.status = "";
          items.tip = "";
        }
        return items;
      });
      if (item.hasUp) {
        this.files = this.files.map((items) => {
          if (items.id == item.id) {
            items.start = false;
            items.status = "success";
            items.success = true;
            items.percentage = 100;
            this.hideItem(items);
          }
          return items;
        });
        this.hideBox();
        return;
      }
      let params = {
        __ajax: "json",
        fileMd5: md5(item.name),
        fileName: item.name,
        bizKey: this.bizKey,
        bizType: this.bizType,
        uploadType: this.uploadType,
        fileUploadId: item.fileUploadId,
        fileEntityId: item.fileEntityId,
        file: item.file,
      };
      window.config.api == "/papi" ? (params.__sid = window.config.sid) : null;
      this.$api
        .post("/a/file/upload.json", this.$common.getFormData(params), {
          mum: true,
          transformRequest: [
            function (data) {
              return data;
            },
          ],
          onUploadProgress: (progressEvent) => {
            let complete =
              ((progressEvent.loaded / progressEvent.total) * 100) | 0;
            this.files = this.files.map((items) => {
              if (items.id == item.id) {
                items.percentage = complete;
              }
              return items;
            });
          },
        })
        .then((res) => {
          if (res.result == "true") {
            this.files = this.files.map((items) => {
              if (items.id == item.id) {
                items.start = false;
                items.percentage = 100;
                items.fileUploadId = res.fileUpload.id;
                items.fileEntityId =
                  res.fileEntityId || res.fileUpload.fileEntity.id;
                items.status = "success";
                items.success = true;
                items.res = res;
                this.hideItem(items);
              }
              return items;
            });
          } else {
            this.files = this.files.map((items) => {
              if (items.id == item.id) {
                items.start = false;
                items.error = true;
                items.status = "exception";
                items.tip = res.message;
              }
              return items;
            });
          }
          this.hideBox();
        })
        .catch((err) => {
          this.files = this.files.filter((items) => {
            return items.id != item.id;
          });
          if (i == this.files.length - 1) this.hideBox();
        });
    },
    hideItem(item) {
      setTimeout(
        (items) => {
          this.files = this.files.map((items2) => {
            if (items2.id == items.id) {
              items2.hide = 1;
            }
            return items2;
          });
          setTimeout(
            (items2) => {
              this.files = this.files.map((items3) => {
                if (items3.id == items2.id) {
                  items3.hide = 2;
                }
                return items3;
              });
              this.files = [];
            },
            500,
            items
          );
        },
        500,
        item
      );
    },
    submit() {
      this.files.forEach((item, index) => {
        this.goUp(item, index);
      });
    },
    goView(item) {
      // this.title = `在线预览-${item.fileName}`;
      // let sid = "";
      // if (window.config.api == "/papi") {
      //   sid = `&__sid=${window.config.sid}`;
      // }
      // this.dialogVisible = true;
      // this.$nextTick(() => {
      //   this.fileUrl = `https://www.yizao2025.com/a/file/download/${item.id}?preview=true${sid}`;
      // });
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `/a/file/download/${item.id}?preview=true`;
    },
    goDown(item) {
      // this.$api
      //   .post(
      //     "/api/v1/updateDownLoadCount",
      //     this.$common.getFormData({
      //       fileUploadId: item.id,
      //       yzTaskContractId: this.keyName,
      //     })
      //   )
      //   .then((res) => console.log(res));
      var tempwindow = window.open("_blank");
      tempwindow.location.href = `/a/file/download/${item.id}`;
    },
  },
  mounted() {
    var box = document.getElementById("drop_area"); //拖拽区域
    if (!box) return;
    box.addEventListener(
      "drop",
      (e) => {
        var fileList = e.dataTransfer.files; //获取文件对象
        //检测是否是拖拽文件到页面的操作
        if (fileList.length == 0) {
          return false;
        }
        this.files = fileList;
        this.checkFile(fileList);
      },
      false
    );
  },
};
</script>
<style lang="scss">
.upload {
  &.btn {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    .pre-list.on {
      margin-left: 10px;
    }
  }
  &.image {
    font-size: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    .pre-list.on {
      margin-left: 10px;
    }
  }
  &.contract {
    font-size: 12px;
    text-align: center;
  }
}
.contract-file-list {
  margin-bottom: 40px;
  &.on {
    .contract-file-item {
      background-color: white;
      .name {
        span {
          color: #999999;
        }
      }
    }
  }
  .contract-file-item {
    line-height: 50px;
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    background-color: fade-out($color: $c, $amount: 0.9);
    border-bottom: 1px solid #e5e5e5;
    font-size: 18px;
    .name {
      display: flex;
      align-content: center;
      font-size: 18px;
      width: 40%;
      @include line();
      span {
        font-size: 18px;
        color: $c;
        margin-right: 5px;
        line-height: 50px;
      }
    }
    .size {
      width: 15%;
    }
    .date {
      width: 20%;
    }
    .down {
      width: 15%;
      span {
        color: $c;
        cursor: pointer;
      }
    }
    .del {
      font-size: 18px;
      color: $c;
      cursor: pointer;
    }
  }
}
.up-load {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px dashed #e5e5e5;
  border-radius: 5px;
  font-size: 14px;
  &.contract {
    display: inline-block;
    border: 0;
    width: auto;
    height: auto;
    & > span {
      color: $c;
    }
    .tip-text {
      font-size: 16px;
      margin-bottom: 30px;
      margin-top: 50px;
    }
  }
  &.btn {
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    & > span {
      color: $c;
    }
  }
  &.image {
    border: 0;
    display: inline-block;
    width: auto;
    height: auto;
    text-align: center;
    & > span {
      color: $c;
    }
  }
  &:hover {
    border-color: $c;
  }
  input {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    font-size: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  .image-list {
    position: relative;
    &:hover {
      & > p {
        opacity: 1;
      }
    }
    & > span {
      display: block;
      text-align: center;
      line-height: 60px;
      font-size: 12px;
      color: #999999;
    }
    & > p {
      opacity: 0;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      z-index: 9;
      text-align: center;
      line-height: 60px;
      text-align: center;
      font-size: 12px;
      color: white;
      cursor: pointer;
    }
    &.sign {
      margin-bottom: 10px;
      width: 400px;
      border: 2px dashed #e5e5e5;
      & > span {
        line-height: 200px;
      }
      & > p {
        line-height: 200px;
      }
      .el-image {
        width: 400px;
        height: 200px;
        display: block;
      }
    }
    .el-image {
      width: 60px;
      height: 60px;
      display: block;
    }
  }
  p {
    font-size: 16px;
  }
  .info {
    color: $c;
  }
  .success {
    color: green;
  }
  .error {
    color: red;
  }
  .warn {
    color: #e6a23c;
  }
}
.up-list {
  position: fixed;
  z-index: 999999;
  right: -100%;
  bottom: 10px;
  max-height: 90vh;
  background-color: white;
  width: 300px;
  padding: 10px;
  transition: 0.5s right;
  &.on {
    right: 10px;
  }
  .item {
    padding: 10px 5px;
    border-bottom: 1px solid #e5e5e5;
    transition: 0.5s opacity;
    &.hide {
      display: none;
    }
    &:last-child {
      border-bottom: 0;
    }
    &.on {
      opacity: 0;
    }
    .title {
      display: flex;
      justify-content: space-between;
      & > span {
        font-weight: 600;
        max-width: 60%;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
      }
      div {
        span {
          font-size: 18px;
          margin-left: 10px;
          cursor: pointer;
          &:hover {
            color: $c;
          }
        }
      }
    }
    .tip {
      color: red;
      font-size: 12px;
      line-height: 1;
      margin: 5px 0;
      text-align: left;
    }
    .el-progress {
      width: 100%;
      .el-progress__text {
        float: right;
      }
    }
  }
}
.pre-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .item {
    border: 1px solid #e5e5e5;
    padding: 5px;
    line-height: 15px;
    font-size: 14px;
    margin: 5px;
    &.on {
      color: green;
    }
    &.err {
      color: red;
    }
    &.none {
      display: none;
    }
    .el-icon-delete {
      cursor: pointer;
      color: #333333;
      &:hover {
        color: $c;
      }
    }
  }
}
</style>